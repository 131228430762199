@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:100;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Thin.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:100;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Thin-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:200;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-ExtraLight.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:200;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-ExtraLight-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:300;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Light.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:300;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Light-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:400;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:400;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Regular-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:500;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Medium.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:500;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Medium-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:600;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-SemiBold.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:600;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-SemiBold-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:700;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Bold.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:700;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Bold-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:800;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-ExtraBold.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:800;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-ExtraBold-Italic.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:normal;
    font-weight:900;
    font-display: swap;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Black.otf) format('opentype');
}

@font-face {
    font-family: 'Jeko';
    font-style:italic;
    font-weight:900;
    src: local('Jeko'), url(https://downloadserver-cdn.nelogica.com.br/content/educational/fonts/Jeko/Jeko-Black-Italic.otf) format('opentype');
}